import React, { useState } from "react"
import { useStore } from "../utils/store"
import { InView } from "react-intersection-observer"
import { PieChart } from "react-minimal-pie-chart"
import Arrow from "./Arrow"
import { StaticImage } from "gatsby-plugin-image"

function Article({ heading, content }) {
  return (
    <div className="article border-b border-ink mx-4 my-8 pb-4">
      {heading && (
        <h2
          className="article-heading text-center text-4xl sm:text-5xl md:text-5xl font-bold mb-4 underline"
          style={{ color: "#4a3732" }}
        >
          {heading}
        </h2>
      )}
      {content}
    </div>
  )
}

export default function News({ t }) {
  const { toggleNews, openNews } = useStore()
  const [showFirstPie, toggleFirstPie] = useState(false)
  const [showSecondPie, toggleSecondPie] = useState(false)

  return (
    <div
      className={`fixed flex flex-col inset-x-0 top-24 w-full p-9 -m-0 delay-75 duration-150 min-h-screen max-w-screen-2xl mx-auto transition-transform h-full max-h-screen ${
        openNews ? "translate-y-0" : "translate-y-full"
      } text-ink bg-newspaper-border bg-cover`}
    >
      {/* Close Button */}
      <div className="absolute top-12 right-14 z-50">
        <button
          className="text-black cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
          type="button"
          onClick={() => toggleNews(!openNews)}
        >
          <span className="sr-only">{t("openMenu")}</span>
          <svg
            className="w-6 h-6"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17 0.5C7.8875 0.5 0.5 7.8875 0.5 17C0.5 26.1125 7.8875 33.5 17 33.5C26.1125 33.5 33.5 26.1125 33.5 17C33.5 7.8875 26.1125 0.5 17 0.5ZM22.5605 13.5605C22.8337 13.2776 22.9849 12.8987 22.9815 12.5054C22.9781 12.1121 22.8203 11.7359 22.5422 11.4578C22.2641 11.1797 21.8879 11.0219 21.4946 11.0185C21.1013 11.0151 20.7224 11.1663 20.4395 11.4395L17 14.879L13.5605 11.4395C13.4221 11.2962 13.2566 11.182 13.0736 11.1033C12.8906 11.0247 12.6938 10.9834 12.4946 10.9816C12.2954 10.9799 12.0979 11.0178 11.9136 11.0933C11.7292 11.1687 11.5617 11.2801 11.4209 11.4209C11.2801 11.5617 11.1687 11.7292 11.0933 11.9136C11.0178 12.0979 10.9799 12.2954 10.9816 12.4946C10.9834 12.6938 11.0247 12.8906 11.1033 13.0736C11.182 13.2566 11.2962 13.4221 11.4395 13.5605L14.879 17L11.4395 20.4395C11.2962 20.5779 11.182 20.7434 11.1033 20.9264C11.0247 21.1094 10.9834 21.3062 10.9816 21.5054C10.9799 21.7046 11.0178 21.9021 11.0933 22.0864C11.1687 22.2708 11.2801 22.4383 11.4209 22.5791C11.5617 22.7199 11.7292 22.8313 11.9136 22.9067C12.0979 22.9822 12.2954 23.0201 12.4946 23.0184C12.6938 23.0166 12.8906 22.9753 13.0736 22.8967C13.2566 22.818 13.4221 22.7038 13.5605 22.5605L17 19.121L20.4395 22.5605C20.7224 22.8337 21.1013 22.9849 21.4946 22.9815C21.8879 22.9781 22.2641 22.8203 22.5422 22.5422C22.8203 22.2641 22.9781 21.8879 22.9815 21.4946C22.9849 21.1013 22.8337 20.7224 22.5605 20.4395L19.121 17L22.5605 13.5605Z"
              fill="#4A3732"
            />
          </svg>
        </button>
      </div>
      {/* Scrollable Content */}
      <div className="overflow-y-scroll h-full w-full md:w-11/12 lg:w-11/12 xl:w-11/12 2xl:w-11/12 mx-auto gap-4 scrollbar-hide pb-10 pt-12">
        {/* Newspaper Header */}
        <div className="text-center py-4">
          <h1 className="text-7xl md:text-9xl font-chomsky">
            {t("newsParagraph16Heading")}
          </h1>
          <div className="bg-black h-1.5 my-2 lg:my-4 xl:my-6 2xl:my-8"></div>
          <div className="grid grid-cols-2 grid-rows-2">
            <div className="flex items-center justify-center row-span-1 text-5xl md:text-9xl font-notable text-[#ff0000]">
              ADA
            </div>
            <div className="flex items-center justify-center row-span-1 text-2xl md:text-5xl font-notable bg-custom-gray">
              {t("newsParagraph17Heading")}
            </div>
            <div className="col-span-2 row-span-1 font-notable text-[#ff0000] tracking-wider text-5xl sm:text-7xl md:text-9xl max-w-full">
              <span className="md:hidden w-full max-w-screen-md">MADBULL</span>
              <span className="hidden md:inline">
                {t("newsParagraph18Heading")}
              </span>
            </div>
          </div>
        </div>

        {/* Introduction Section */}
        <div className="flex flex-col-reverse md:grid md:grid-cols-2 w-11/12 md:w-auto">
          <div className="md:order-1 mb-4 md:mb-0">
            <Article
              heading={t("newsParagraph0Heading")}
              content={
                <>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph0")}
                  </p>
                  <blockquote className="font-semibold mt-4 mb-4 italic">
                    {t("newsParagraph1")}
                  </blockquote>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph2")}
                  </p>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph3")}
                  </p>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph4")}
                  </p>
                  <blockquote className="font-semibold mt-4 mb-4 italic">
                    {t("newsParagraph5")}
                  </blockquote>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph6")}
                  </p>
                </>
              }
            />
          </div>
          <div className="order-1 md:order-2">
            <StaticImage
              src="../images/bull-in-field.png"
              alt="Bull in Field"
              className="w-full h-auto mb-4"
            />
          </div>
        </div>

        <div className="text-[#4A3732] px-4 mt-[-10] col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            <Article
              heading={t("newsParagraph7Heading")}
              content={
                <p className="article-content text-justify mb-4">
                  {t("newsParagraph7")}
                </p>
              }
            />
            <Article
              heading={t("newsParagraph8Heading")}
              content={
                <p className="article-content text-justify mb-4">
                  {t("newsParagraph8")}
                </p>
              }
            />
            <Article
              heading={t("newsParagraph9Heading")}
              content={
                <>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph9")}
                  </p>
                  <p className="article-content text-justify mb-4">
                    {t("newsParagraph10")}
                  </p>
                </>
              }
            />
          </div>

          <InView
            as="div"
            onChange={(inView, entry, ref) => {
              !showFirstPie && toggleFirstPie(inView)
              !showSecondPie && toggleSecondPie(inView)
            }}
            threshold="0.6"
          >
            <div
              className="py-4 w-11/12 m-auto flex justify-center items-center flex-nowrap"
              style={{ maxWidth: "35%" }}
            >
              <PieChart
                className="hidden md:flex"
                data={[
                  {
                    title: `50% ${t("graphLable1")}`,
                    value: 50,
                    color: "#C13C37",
                  },
                  {
                    title: `50% ${t("graphLable2")}`,
                    value: 50,
                    color: "#6A2135",
                  },
                ]}
                labelStyle={() => ({
                  fontSize: showFirstPie ? "5px" : "3px",
                  fontWeight: "600",
                  color: "#4A3732",
                })}
                style={{ overflow: "visible" }}
                label={({ dataEntry }) => dataEntry.title}
                animate
                startAngle={180}
                radius={35}
                labelPosition={110}
                reveal={showFirstPie ? 100 : 0}
              />
              <PieChart
                className="flex px-4 md:hidden"
                data={[
                  {
                    title: `50% ${t("graphLable1")}`,
                    value: 50,
                    color: "#C13C37",
                  },
                  {
                    title: `50% ${t("graphLable2")}`,
                    value: 50,
                    color: "#6A2135",
                  },
                ]}
                labelStyle={() => ({
                  fontSize: showFirstPie ? "5px" : "3px",
                  fontWeight: "600",
                  color: "#4A3732",
                })}
                style={{ overflow: "visible" }}
                label={({ dataEntry }) => dataEntry.title}
                animate
                startAngle={180}
                radius={35}
                labelPosition={110}
                reveal={showFirstPie ? 100 : 0}
              />

              <div className="mx-2 md:mx-4 transform rotate-180">
                <Arrow />
              </div>

              <PieChart
                className="hidden md:flex"
                data={[
                  {
                    title: `75% ${t("graphLable3")}`,
                    value: 75,
                    color: "#C13C37",
                  },
                  {
                    title: `5% ${t("graphLable7")}`,
                    value: 5,
                    color: "#FFFFFF",
                  },
                  {
                    title: `20% ${t("graphLable6")}`,
                    value: 20,
                    color: "#FFD904",
                  },
                ]}
                labelStyle={() => ({
                  fontSize: showFirstPie ? "5px" : "3px",
                  fontWeight: "600",
                  color: "#4A3732",
                })}
                style={{ overflow: "visible" }}
                label={({ dataEntry }) => dataEntry.title}
                animate
                startAngle={312}
                radius={35}
                labelPosition={110}
                reveal={showSecondPie ? 100 : 0}
              />
              <PieChart
                className="flex px-4 md:hidden"
                data={[
                  {
                    title: `75% ${t("graphLable3")}`,
                    value: 75,
                    color: "#C13C37",
                  },
                  {
                    title: `5% ${t("graphLable7")}`,
                    value: 5,
                    color: "#FFFFFF",
                  },
                  {
                    title: `20% ${t("graphLable6")}`,
                    value: 20,
                    color: "#FFD904",
                  },
                ]}
                labelStyle={() => ({
                  fontSize: showSecondPie ? "5px" : "3px",
                  fontWeight: "600",
                  color: "#4A3732",
                })}
                style={{ overflow: "visible" }}
                label={({ dataEntry }) => dataEntry.title}
                animate
                startAngle={320}
                radius={35}
                labelPosition={110}
                reveal={showSecondPie ? 100 : 0}
              />
            </div>
          </InView>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            <Article
              heading={t("newsParagraph11Heading")}
              content={
                <p className="article-content text-justify mb-4">
                  {t("newsParagraph11")}
                </p>
              }
            />
            <Article
              heading={t("newsParagraph13Heading")}
              content={
                <p className="article-content text-justify mb-4">
                  {t("newsParagraph13")}
                </p>
              }
            />
            <Article
              heading={t("newsParagraph15Heading")}
              content={
                <p className="article-content text-justify mb-4">
                  {t("newsParagraph15")}
                </p>
              }
            />
          </div>
          <div className="py-10" />
        </div>
      </div>
    </div>
  )
}
