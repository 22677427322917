import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { graphql } from "gatsby"

import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"

import { useStore } from "../utils/store"

import Header from "../components/header"
import Faucet from "../components/faucet"
import News from "../components/news"
import World from "../components/world"

const IndexPage = () => {
  const { toggleFaucet, toggleNews, toggleWorld, openFaucet, openWorld } =
    useStore()

  const { t } = useTranslation()
  const { language } = useI18next()

  const [showVideo, setShowVideo] = React.useState(false) // New state variable for controlling the video overlay

  const handleNewsButtonClick = () => {
    console.log("News button clicked") // debug log
    setShowVideo(true) // Show the video overlay when the News button is clicked
  }

  const [showWorldVideo, setShowWorldVideo] = React.useState(false)

  const handleWorldButtonClick = () => {
    console.log("World button clicked") // debug log
    setShowWorldVideo(true) // Show the world video overlay when the World button is clicked
  }

  return (
    <div className="relative flex flex-col items-center justify-center min-h-[600px] h-screen sm:max-h-screen overflow-y-hidden bg-[#4A3732]">
      <Header location={{ pathname: "home" }} t={t} />

      <main className="flex flex-col items-center justify-center w-full flex-1 text-center max-w-screen-2xl mx-auto lg:overflow-hidden">
        <div className="flex flex-col lg:flex-row h-full w-full">
          <div className="block w-full lg:min-w-[15rem] flex-1"></div>
          <div className="fixed inset-x-0 top-20 md:inset-0 flex flex-3 w-full justify-center items-center">
            <div className="w-full pt-14 m-auto max-h-[300px] max-w-[300px]  md:max-h-[500px] md:max-w-[500px] relative">
              {language === "en" ? (
                <StaticImage
                  className="animate-fade-in-down delay-75"
                  src={"../images/madbull.webp"}
                  alt="Picture of the MAdbuLL"
                  placeholder="blurred"
                  height={500}
                  width={500}
                />
              ) : (
                <StaticImage
                  className="animate-fade-in-down delay-75"
                  src={"../images/toroloco.webp"}
                  alt="Foto del MAdbuLL"
                  placeholder="blurred"
                  height={500}
                  width={500}
                />
              )}
              <p className="text-[0.5rem] sm:text-sm md:text-base font-bold text-white">
                {" "}
                ea2d23f1fa631b414252824c153f2d6ba833506477a929770a4dd9c2
              </p>
            </div>
          </div>
          <div className="flex flex-row lg:flex-col px-8 pb-12 md:p-8 space-x-5 lg:space-y-5 w-full h-full justify-between md:justify-end items-end">
            <div
              className="hover:scale-125 delay-75 duration-150 cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={handleWorldButtonClick}
              onKeyDown={e => e.key === "Enter" && toggleWorld(!openWorld)}
            >
              <StaticImage
                src={"../images/world2.webp"}
                alt="Picture of the Blimped Out Bull on Top of the World"
                placeholder="blurred"
                height={180}
                width={150}
              />
            </div>
            <div
              className="hover:scale-125 delay-75 duration-150 cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={handleNewsButtonClick}
              onKeyDown={e => e.key === "Enter" && handleNewsButtonClick()}
            >
              {language === "en" ? (
                <StaticImage
                  src={"../images/newsenglish.webp"}
                  alt="Picture of the News Bull"
                  placeholder="blurred"
                  height={125}
                  width={135}
                />
              ) : (
                <StaticImage
                  src={"../images/newsspanish.webp"}
                  alt="Foto del News Bull"
                  placeholder="blurred"
                  height={125}
                  width={135}
                />
              )}
            </div>
            <div
              className="hover:scale-125 delay-75 duration-150 cursor-pointer "
              role="button"
              tabIndex={0}
              onClick={() => {
                toggleFaucet(!openFaucet)
              }}
              onKeyDown={e => e.key === "Enter" && toggleFaucet(!openFaucet)}
            >
              <StaticImage
                src={"../images/faucet.webp"}
                alt="Picture of the Faucet Bull"
                placeholder="blurred"
                height={125}
                width={125}
              />
            </div>

            {showVideo && (
              <div className="fixed inset-0 z-1000 animate-widen">
                <img
                  src="/images/news-today.webp"
                  style={{
                    animationPlayState: showVideo ? "running" : "paused",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onLoad={() => {
                    setTimeout(() => {
                      setShowVideo(false)
                      toggleNews(true)
                    }, 1300)
                  }}
                  alt="News transition animation"
                />
              </div>
            )}
            {showWorldVideo && (
              <div className="fixed inset-0 z-1000 animate-widen h-screen">
                <img
                  src="/world-bull.webp"
                  alt="World transition animation"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onLoad={() => {
                    setTimeout(() => {
                      setShowWorldVideo(false)
                      toggleWorld(true)
                    }, 900)
                  }}
                />
              </div>
            )}
          </div>
          <div className="block sm:hidden w-full lg:min-w-[15rem] flex-1"></div>
        </div>
      </main>
      <World t={t} />
      <Faucet t={t} />
      <News t={t} />
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
