import * as React from "react"

const SvgComponent = props => (
  <svg
    className="animate-bounce2 w-10 h-10 md:w-28 md:h-40"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 201.189 201.189"
    style={{
      enableBackground: "new 0 0 201.189 201.189",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g transform="rotate(90, 100.5945, 100.5945)">
      <path
        style={{
          fill: "#2aa7e1",
        }}
        d="M102.246 201.189c-4.373 0-7.38-3.393-7.709-3.786l-60.3-72.869c-1.596-1.482-5.712-5.948-4.155-9.853.92-2.319 3.332-3.5 7.158-3.5h31.086V8.296c-.154-.97-.276-3.189 1.267-5.186C71.182 1.049 73.901 0 77.67 0h47.781c3.382 0 5.336 1.281 6.378 2.355 1.958 2.015 1.99 4.456 1.976 4.921v101.743h28.491c1.757 0 7.115 0 8.75 3.962 1.707 4.137-2.434 8.704-3.74 10.003-1.829 2.473-51.221 67.995-58.407 75.192-2.487 2.487-4.989 3.013-6.653 3.013zm-66.563-84.125c.437.927 1.589 2.434 2.759 3.55l60.515 73.087c.39.451 1.832 1.721 3.289 1.721.848 0 1.686-.433 2.577-1.317 6.907-6.918 57.634-74.211 58.153-74.898 1.478-1.525 2.656-3.35 2.706-4.069-.147.018-.981-.365-3.386-.365h-34.25V7.19c-.043-.948-.916-1.424-2.595-1.424H77.67c-2.065 0-3.16.433-3.5.845-.243.297-.161.766-.161.766l.075.655V116.94H37.24a8.452 8.452 0 0 0-1.557.124z"
      />
    </g>
  </svg>
)

export default SvgComponent
